import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { SubTopicLayout } from '../../components/entities/sub-topic-layout';

export default createGatsbyPageComponent('SubTopic', { layout: SubTopicLayout });

export const subTopicQuery = graphql`
  query SubTopicQuery($id: ID!) {
    platform {
      subTopic(id: $id) {
        __typename _schema { label pluralLabel }
        id slug name __typename outlineNumber
        intro { text }
        color { id name slug value }
        embeds {
          citations {
            slug url name authors { name } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
          }
          imageAssets {
            id
            slug
            image {
              id url width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        banner { 
          id name slug 
          image { 
            id url static { childImageSharp { gatsbyImageData(placeholder: NONE) } }
          }
        }
        innovations {
          __typename _schema { label pluralLabel }
          openGraph { description { text(truncate: { words: 20 }) } image { url } }
          id slug path name
          description { text }
          horizons { name id type { value } }
        }
        description { text }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug url published websiteName summary { text }
          image { url static { childImageSharp { gatsbyImageData } } }
          openGraph { image { url static { childImageSharp { gatsbyImageData } } } }
        }
        horizons {
          __typename id name slug
          intro { text }
          description { text }
          color { __typename id name slug value }
          type { __typename id name slug years title subtitle }
          embeds {
            citations {
              slug url name authors { id name } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
            }
          }
        }
        topic { 
          id name slug __typename
          outlineNumber
          color { id name slug value }
          openGraph { id image { id url } }
          # globalExperts {
          #   __typename id name slug position
          #   photo { id url }
          #   organisation { id name slug }
          # }
          trend { 
            id name outlineNumber slug __typename
          } 
        }
        # connectedTrends {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedTopics {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedSubTopics {
        #   id name slug path outlineNumber color { value }
        # }
        # connectedPages {
        #   id name slug path type { name }
        # }
      }
    }
  }
`;
