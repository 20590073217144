import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityIntro, ContentNode, NextPageLink, classNames, useEntity, useView, DottedLine } from '@shapeable/web';
import { TabSpec, Tabs } from '../elements/tabs';
const cls = classNames('topic-main-layout');
import { sprintf } from 'sprintf-js';
import { SubTopic } from '@shapeable/collaborate-ore-types';
import { InnovationHorizonTableList } from './innovation-horizon-table-list';

import { compact } from 'lodash';
import { ExplorerView } from '../elements/explorer-view';

// -------- Types -------->

export type SubTopicView = 'overview' | 'innovations';

export type SubSubTopicMainLayoutProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubSubTopicMainLayoutDefaultProps: Omit<SubSubTopicMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.UNIT(5)};
    gap: ${theme.UNIT(5)};
  `,
});


const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: 1.35em; 
  `,
});

const NextLinkStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
  `,
});


const TabsStyles = breakpoints({
  base: css`
    width: 100%;
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const InnovationTableStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    Description: styled(ContentNode)`${DescriptionStyles}`,
    
    Tabs: styled(Tabs)`${TabsStyles}`,
    InnovationTable: styled(InnovationHorizonTableList)`${InnovationTableStyles}`,
      
    NextLink: styled(NextPageLink)`${NextLinkStyles}`,

};

export const SubTopicMainLayout: Shapeable.FC<SubSubTopicMainLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { description, innovations } = entity;
  
  const hasDescription = !!description?.text;

  const tabs: TabSpec<SubTopicView>[] = compact([
    hasDescription && {
      id: 'overview',
      label: 'Overview',
    },
    !!innovations.length && {
      id: 'innovations',
      label: 'Innovations',
    },
  ]);

  const defaultView = hasDescription ? 'overview' : 'innovations';

  const { activeView, setActiveView } = useView<SubTopicView>(`sub-topic-${entity.slug}`, defaultView);

  return (
    <My.Container className={cls.name(className)}>
      {
        tabs.length > 1 &&
        <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} variant='section' />
      }
      <ExplorerView>
      {
        activeView === 'overview' &&
        <My.Section>
          <My.Intro entity={entity} />
          <My.Description entity={description as TContentNode} /> 
        </My.Section>
      }
      {
        activeView === 'innovations' &&
        <My.Section>
          <My.InnovationTable items={innovations} />
        </My.Section>
      }
      <DottedLine height={2} />
      <My.NextLink />
      </ExplorerView>
    </My.Container>
    
  )
};

SubTopicMainLayout.defaultProps = SubSubTopicMainLayoutDefaultProps;
SubTopicMainLayout.cls = cls;